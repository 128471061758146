import { useCallback } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { match } from 'ts-pattern';

import {
  type DtoFeaturedItem,
  EnumsFeaturedItemType,
} from '@lp-lib/api-service-client/public';

import { FEATURED_GAME_PACKS_SHOW_CAP } from '../../../app/components/GamePack/GamePackCollection';
import { apiService } from '../../services/api-service';
import { TaggedObjectType } from '../../types';
import { toDtoTag } from '../../utils/api-dto';
import { assertExhaustive } from '../../utils/common';
import { DragDropList } from '../common/DragDrop';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { TagPicker, TagUtils } from '../Tagging';

function FeaturedRow(props: {
  item: DtoFeaturedItem;
  drag: ConnectDragSource;
  onDelete: () => void;
}) {
  const { item, drag, onDelete } = props;

  const name = match(item.type)
    .with(
      EnumsFeaturedItemType.FeaturedItemTypeGamePack,
      () => item.gamePack?.name
    )
    .with(EnumsFeaturedItemType.FeaturedItemTypeTag, () => item.tag?.name)
    .exhaustive();

  const onClick = () => {
    switch (item.type) {
      case EnumsFeaturedItemType.FeaturedItemTypeGamePack:
        window.open(`/admin/gamepacks/edit/${item.gamePack?.id}`, '_blank');
        break;
      case EnumsFeaturedItemType.FeaturedItemTypeTag:
        window.open(`/admin/toolkit/tags/${item.tag?.id}`, '_blank');
        break;
      default:
        assertExhaustive(item.type);
    }
  };

  return (
    <div className={`w-full flex items-center gap-2`}>
      <button type='button' ref={drag} className='btn cursor-move'>
        <MenuIcon />
      </button>

      <div
        onClick={onClick}
        className='flex-1 h-10 cursor-pointer hover:bg-lp-gray-002 flex items-center justify-between px-3 border border-secondary rounded-xl'
      >
        <div className='text-sms'>
          {name}
          <span className='text-secondary'>
            {item.type === EnumsFeaturedItemType.FeaturedItemTypeTag
              ? ` (category)`
              : ''}
          </span>
        </div>
      </div>

      <button
        type='button'
        className='btn flex w-10 h-10 border border-secondary rounded-xl items-center justify-center text-red-002'
        onClick={onDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

interface FeaturedFormData {
  items: DtoFeaturedItem[];
}

function EditFeaturedManagement(props: {
  defaultValues: FeaturedFormData;
  onSave: (data: FeaturedFormData) => Promise<void>;
  onClose: () => void;
}) {
  const { defaultValues, onSave, onClose } = props;

  const { control, handleSubmit, formState } = useForm<FeaturedFormData>({
    defaultValues,
  });
  const {
    fields: items,
    remove,
    move,
    prepend,
  } = useFieldArray<FeaturedFormData, 'items', 'key'>({
    control: control,
    name: 'items',
    keyName: 'key',
  });

  return (
    <form
      className='w-full h-full px-5 py-8 flex flex-col items-center gap-8'
      onSubmit={handleSubmit(onSave)}
    >
      <h2 className='text-2xl font-medium'>Edit Featured</h2>

      <main className='w-full flex flex-col gap-4'>
        <div className='text-base font-medium my-2'>
          Add a Featured Category
        </div>
        <TagPicker
          placeholder='Search for an existing Category'
          filter={(tag) =>
            TagUtils.GetObjectsCount(tag, TaggedObjectType.PrimeGamePack) > 0 &&
            !items.find((item) => item.id === `${tag.id}`)
          }
          onChange={(tag) =>
            prepend({
              id: String(tag.id),
              type: EnumsFeaturedItemType.FeaturedItemTypeTag,
              tag: toDtoTag(tag),
            })
          }
          multi={false}
        />

        <h3 className='text-base font-bold'>Featured</h3>

        <div className='w-full'>
          {items.length === 0 && (
            <div className='w-full text-center text-secondary'>No featured</div>
          )}

          <div className='flex flex-col gap-2'>
            <DragDropList
              type='featured-game-packs'
              items={items}
              render={({ item, index, drag, ref, style }) => (
                <>
                  {index === FEATURED_GAME_PACKS_SHOW_CAP && (
                    <div className='my-5 flex items-center gap-2'>
                      <hr className='w-1/2 border-lp-gray-002' />
                      <p className='flex-none text-sms'>
                        👇 exclusively for promotion only
                      </p>
                      <hr className='w-1/2 border-lp-gray-002' />
                    </div>
                  )}

                  <div ref={ref} style={style}>
                    <FeaturedRow
                      item={item}
                      drag={drag}
                      onDelete={() => remove(index)}
                    />
                  </div>
                </>
              )}
              onMove={(from, to) => move(from, to)}
            />
          </div>
        </div>
      </main>

      <div className='flex items-center gap-4'>
        <button
          type='button'
          className='btn-secondary w-40 h-10'
          onClick={onClose}
        >
          Cancel
        </button>
        <button type='submit' className='btn-primary w-40 h-10'>
          {formState.isSubmitting ? 'Saving' : 'Save'}
        </button>
      </div>
    </form>
  );
}

export function useTriggerEditFeaturedModal() {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: { onSave?: () => void }) => {
      const resp = await apiService.page.getFeatured({
        size: null,
      });
      const items = resp.data.items;

      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-160'
            borderStyle='white'
            onClose={p.internalOnCancel}
          >
            <EditFeaturedManagement
              defaultValues={{
                items,
              }}
              onSave={async (data) => {
                const ids = data.items.map((item) => item.id);
                await apiService.settings.updateValue('game-packs', {
                  key: 'featured',
                  value: ids,
                });

                p.internalOnConfirm();
                if (props.onSave) {
                  props.onSave();
                }
              }}
              onClose={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerFullScreenModal]
  );
}

export function EditFeaturedButton(props: { onSave?: () => void }) {
  const triggerEditModal = useTriggerEditFeaturedModal();

  const handleClick = () => {
    triggerEditModal({ onSave: props.onSave });
  };

  return (
    <button
      type='button'
      className={`btn-secondary text-white w-44 h-10`}
      onClick={handleClick}
    >
      Edit Featured
    </button>
  );
}
