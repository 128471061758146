import {
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Waypoint } from 'react-waypoint';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import {
  type DtoPageSection,
  EnumsPageName,
  EnumsPageRowLayout,
} from '@lp-lib/api-service-client/public';

import { PageSectionList } from '../../app/components/DynamicPage/PageSectionList';
import {
  type PageSectionRowFeaturedTagContainerProps,
  type PageSectionRowTagCollectionContainerProps,
} from '../../app/components/DynamicPage/PageSectionRow';
import {
  pageSectionAnchor,
  selectSectionByAnchor,
} from '../../app/components/DynamicPage/utils';
import {
  FeaturedCard,
  FeaturedSlice,
} from '../../app/components/Featured/FeaturedCard';
import { FeaturedList } from '../../app/components/Featured/FeaturedList';
import {
  ProgramDetailsModal,
  useSearchParamProgramId,
} from '../../app/components/Programs/Details/ProgramDetailsCard';
import { ProgramDetailsSideActionAdmin } from '../../app/components/Programs/Details/ProgramDetailsSideAction';
import { AdminGamePackCard } from '../../app/components/PublicLibrary/AdminGamePack';
import {
  AdminPublicLibraryLayout,
  useAdminPublicLibraryData,
} from '../../app/components/PublicLibrary/AdminPublicLibraryLayout';
import { PublicLibraryBreadcrumbs } from '../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import {
  encodeFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysMain,
} from '../../app/components/PublicLibrary/PublicLibraryFilter';
import {
  PublicLibraryGamePackCarousel,
  PublicLibraryGamePackCarouselLoading,
  PublicLibraryGamePackList,
  PublicLibraryGamePackListLoading,
  PublicLibraryTagCarousel,
  PublicLibraryTagList,
} from '../../app/components/PublicLibrary/PublicLibraryLayout';
import { PublicLibraryProgramsSection } from '../../app/components/PublicLibrary/PublicLibraryPrograms';
import { TagCard } from '../../app/components/Tag/TagCard';
import { type Action, ActionSheet } from '../components/ActionSheet';
import {
  ErrorMessage,
  type GameLikeFilterOptions,
  useGameLikeWorkspace,
  useTriggerManagePinnedGamePacksModal,
} from '../components/Game/GameCenter';
import { GamePackFeaturedActionsAdmin } from '../components/Game/GamePack/GamePackFeaturedActions';
import { DoubleRightArrow } from '../components/icons/Arrows';
import { CopyIcon } from '../components/icons/CopyIcon';
import { DownloadIcon } from '../components/icons/DownloadIcon';
import { EditIcon } from '../components/icons/EditIcon';
import { useTriggerEditFeaturedModal } from '../components/PageManager/EditFeaturedButton';
import { PageManagerUtils } from '../components/PageManager/utils';
import { TagUtils } from '../components/Tagging/utils';
import {
  getGamePacksByTagId,
  useSubscribeGamePackEventEmitter,
} from '../pages/GamePack/Collections';
import { apiService } from '../services/api-service';
import { SiteBaseURL } from '../services/public';
import { type Tag, TaggedObjectType } from '../types';
import { fromDTOGamePack } from '../utils/api-dto';

function AdminPublicLibraryActions() {
  const { pageName } = useAdminPublicLibraryData();

  const navigate = useNavigate();
  const triggerEditFeaturedModal = useTriggerEditFeaturedModal();
  const revalidator = useRevalidator();

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit-sections',
      icon: <EditIcon className='w-3.5 h-3.5 fill-current' />,
      text: 'Edit Sections',
      onClick: () => {
        navigate($path('/admin/pages/:name/edit', { name: pageName }));
      },
    },
  ];

  actions.push({
    kind: 'button',
    key: 'edit-featured',
    icon: <EditIcon className='w-3.5 h-3.5 fill-current' />,
    text: 'Edit Featured',
    onClick: () => {
      triggerEditFeaturedModal({
        onSave: () => revalidator.revalidate(),
      });
    },
  });
  actions.push({
    kind: 'link',
    key: 'export',
    icon: <DownloadIcon className='w-3.5 h-3.5 fill-current' />,
    text: 'Export Game Packs',
    href: $path('/admin/public-library/:name/export', { name: pageName }),
  });

  const loggedInPath = PageManagerUtils.GetLoggedInPath(pageName);
  if (loggedInPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-in-link',
      text: 'Copy: Logged In Link',
      icon: <CopyIcon />,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedInPath;
        copy(url.toString());
      },
    });
  }
  const loggedOutPath = PageManagerUtils.GetLoggedOutPath(pageName);
  if (loggedOutPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-out-link',
      text: 'Copy: Logged Out Link',
      icon: <CopyIcon />,

      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedOutPath;
        copy(url.toString());
      },
    });
  }

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-7.5 h-7.5'
      placement='left-start'
    />
  );
}

export async function clientLoader() {
  const featuredItems = (await apiService.page.getFeatured({ size: 8 })).data
    .items;
  const programs = (await apiService.program.getActivePrograms()).data
    .activePrograms;

  return {
    featuredItems,
    programs,
  };
}

export function shouldRevalidate() {
  return false;
}

function AdminPublicLibraryPrograms() {
  const { programs } = useLoaderData<typeof clientLoader>();

  const [programId, setProgramId] = useSearchParamProgramId();
  const program = programs.find((p) => p.id === programId);

  return (
    <>
      <PublicLibraryProgramsSection
        programs={programs}
        onClick={(program) => setProgramId(program.id)}
      />

      {program && (
        <ProgramDetailsModal
          program={program}
          anonymous={false}
          relatedPrograms={programs.filter((p) => p.id !== program.id)}
          breadcrumbs={
            <PublicLibraryBreadcrumbs
              items={[
                {
                  label: 'Explore',
                  kind: 'button',
                  onClick: () => setProgramId(null),
                },
                {
                  label: 'Programs',
                  kind: 'button',
                  onClick: () => setProgramId(null),
                },
                { label: program.name, kind: 'noop' },
              ]}
            />
          }
          sideAction={<ProgramDetailsSideActionAdmin program={program} />}
          onClose={() => setProgramId(null)}
        />
      )}
    </>
  );
}

function AdminPublicLibraryFeaturedCarousel() {
  const { pageName } = useAdminPublicLibraryData();
  const { featuredItems } = useLoaderData<typeof clientLoader>();

  const navigate = useNavigate();
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [filterOptions, setFilterOptions] = useState<
    Partial<GameLikeFilterOptions>
  >({});

  const handleClickTag = (tag: Tag) => {
    navigate(
      $path('/admin/public-library/:name/tags/:slug', {
        name: pageName,
        slug: tag.slug,
      })
    );
  };

  return (
    <div className='w-full flex flex-col items-center gap-5'>
      <div className='w-full max-w-[1380px]'>
        <FeaturedList
          hideBullets
          items={featuredItems}
          renderSlide={(props) => <FeaturedSlice {...props} />}
          renderCard={(props) => (
            <FeaturedCard
              {...props}
              onGamePackClick={(pack) =>
                setActiveGamePack(fromDTOGamePack(pack))
              }
              renderGamePackAction={(pack) => (
                <GamePackFeaturedActionsAdmin pack={fromDTOGamePack(pack)} />
              )}
              onTagClick={(tag) => {
                handleClickTag(tag);
              }}
            />
          )}
        />
      </div>
      <PublicLibraryFilter
        showMenuKeys={PublicLibraryFilterKeysMain}
        filterOptions={filterOptions}
        onChange={setFilterOptions}
        withApplyButton
        onApply={(options) => {
          const params = encodeFilterOptions(options);
          navigate(
            $path(
              '/admin/public-library/:name/search',
              { name: pageName },
              params
            )
          );
        }}
      />
    </div>
  );
}

function AdminPublicLibraryFeaturedTag(
  props: PageSectionRowFeaturedTagContainerProps
) {
  const { tag, row } = props;

  const { pageName } = useAdminPublicLibraryData();
  const managePinnedGamePacks = useTriggerManagePinnedGamePacksModal();
  const [entered, setEntered] = useState(false);

  const { data: packs, mutate } = useSWR(
    entered ? ['/game-packs', tag.id] : null,
    async () => {
      return getGamePacksByTagId(tag.id, false);
    },
    {
      revalidateOnFocus: false,
    }
  );
  useSubscribeGamePackEventEmitter(tag, mutate);

  const showHeader =
    pageName !== EnumsPageName.PageNameLiveOtp &&
    pageName !== EnumsPageName.PageNameLiveLoggedIn;

  return (
    <div className='w-full'>
      <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll></Waypoint>

      {showHeader && (
        <div className='flex items-center gap-8 group'>
          <Link
            to={$path('/admin/public-library/:name/tags/:slug', {
              name: pageName,
              slug: tag.slug,
            })}
            className='btn flex items-center gap-8'
          >
            <p className={`text-xl font-bold`}>{tag.name}</p>
            <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
              <p className='text-sms'>View All</p> <DoubleRightArrow />
            </div>
          </Link>

          <button
            type='button'
            className='btn-secondary w-20 h-6 opacity-0 group-hover:opacity-100 text-sms rounded-md'
            onClick={() => {
              managePinnedGamePacks({
                tag: tag,
                onComplete: () => mutate(),
              });
            }}
          >
            Reorder
          </button>
        </div>
      )}

      {!packs &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackListLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ) : (
          <PublicLibraryGamePackCarouselLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ))}
      {packs && packs.length === 0 && (
        <ErrorMessage text={`No game packs found`} handleRetry={() => mutate} />
      )}
      {packs &&
        packs.length > 0 &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackList
            packs={packs || []}
            renderCard={(pack) => <AdminGamePackCard pack={pack} />}
          />
        ) : (
          <PublicLibraryGamePackCarousel
            packs={packs || []}
            renderCard={(pack) => <AdminGamePackCard pack={pack} />}
          />
        ))}
    </div>
  );
}

function AdminPublicLibraryTagCollection(
  props: PageSectionRowTagCollectionContainerProps
) {
  const { collection, row } = props;

  const { pageName } = useAdminPublicLibraryData();
  const navigate = useNavigate();
  const handleClickTag = (tag: Tag) => {
    navigate(
      $path('/admin/public-library/:name/tags/:slug', {
        name: pageName,
        slug: tag.slug,
      })
    );
  };

  return (
    <div className='w-full'>
      <h3 className={`text-xl font-bold`}>{collection.name}</h3>

      {row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
        <PublicLibraryTagList
          tags={collection.tags || []}
          renderCard={(tag) => (
            <TagCard tag={tag} onClick={() => handleClickTag(tag)} />
          )}
        />
      ) : (
        <PublicLibraryTagCarousel
          tags={collection.tags || []}
          renderCard={(tag) => (
            <TagCard tag={tag} onClick={() => handleClickTag(tag)} />
          )}
        />
      )}
    </div>
  );
}

export function Component() {
  const { pageName, page } = useAdminPublicLibraryData();

  const [focusedSection, setFocusedSection] = useState<DtoPageSection | null>(
    () => (page.sections && page.sections.length > 0 ? page.sections[0] : null)
  );

  useEffectOnce(() => {
    const targetSection = selectSectionByAnchor(
      page.sections,
      window.location.hash
    );
    if (!targetSection) return;

    setFocusedSection(targetSection);
    const element = document.getElementById(pageSectionAnchor(targetSection));
    if (element) {
      element.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  });

  return (
    <AdminPublicLibraryLayout
      isSelectedSection={(section) => section.id === focusedSection?.id}
      actions={<AdminPublicLibraryActions />}
    >
      <PageSectionList
        pageName={pageName}
        sections={page.sections || []}
        onFocusedSectionChange={(section) => setFocusedSection(section)}
        renderPrograms={AdminPublicLibraryPrograms}
        renderFeaturedCarousel={AdminPublicLibraryFeaturedCarousel}
        renderFeaturedTag={AdminPublicLibraryFeaturedTag}
        renderTagCollection={AdminPublicLibraryTagCollection}
      />
    </AdminPublicLibraryLayout>
  );
}
